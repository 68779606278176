import { Box, Button } from "@pankod/refine-mui";
import { InputTextField } from "components/form";
import { SectionProps } from "pages/settings/sections";

export const TelegramSection: React.FC<SectionProps> = ({
  register,
  getValues,
  errors,
}) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <Box
        style={{
          display: "flex",
          gap: "1rem",
        }}
      >
        <InputTextField
          name="telegramApiKey"
          label="API Key do Telegram"
          register={register}
          errors={errors}
          sx={{
            maxWidth: 500,
          }}
        />
        <Button
          variant="outlined"
          style={{
            marginBottom: 8,
          }}
          component="span"
          onClick={() => {
            window.open(
              `https://api.telegram.org/bot${getValues?.(
                "telegramApiKey"
              )}/getUpdates`,
              "_blank"
            );
          }}
        >
          Updates
        </Button>
      </Box>

      <InputTextField
        name="telegramOrderChats"
        label="PEDIDOS: aceita múltiplos valores separados por virgula"
        register={register}
        errors={errors}
        sx={{
          maxWidth: "100%",
        }}
      />

      <InputTextField
        name="telegramAdminChats"
        label="ADMIN: aceita múltiplos valores separados por virgula"
        register={register}
        errors={errors}
        sx={{
          maxWidth: "100%",
        }}
      />
    </Box>
  );
};
