import React, { Fragment, useState } from "react";
import { useForm } from "@pankod/refine-react-hook-form";
import {
  Edit,
  Box,
  Typography,
  Collapse,
  ListItemButton,
  ListItemText,
} from "@pankod/refine-mui";

import { List } from "@mui/material";
import { useUploadImage } from "../../components/useUploadImage/useUploadImage";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  StyleSection,
  SocialSection,
  MessagesSection,
  TicketSection,
  OrderSection,
  CourierSection,
  DiscountSection,
  SEOSection,
  GeneralSection,
  PWASiteSection,
  AnalyticsSection,
  TelegramSection,
} from "pages/settings/sections";

export const SettingsEdit: React.FC = () => {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    watch,
    setValue,
    getValues,
    setError,
  } = useForm({
    refineCoreProps: {
      metaData: {
        populate: ["logo", "favicon", "pwaImage", "pwaImageDelivery"],
      },
    },
  });

  const {
    Component: UploadImage,
    Viewer: ImageViewer,
    handleUpload,
  } = useUploadImage({
    watchFn: watch,
    setValueFn: setValue,
    setErrorFn: setError,
    registerFn: register,
    field: "logo",
    context: "settings",
    size: [500, 500],
    suggestedDimensions: false,
    errors,
  });

  const {
    Component: UploadImageFavicon,
    Viewer: ImageViewerFavicon,
    handleUpload: handleUploadFavicon,
  } = useUploadImage({
    watchFn: watch,
    setValueFn: setValue,
    setErrorFn: setError,
    registerFn: register,
    field: "favicon",
    context: "settings",
    size: [48, 48],
    errors,
    accept: "image/x-icon",
    suggestedDimensions: false,
  });

  const {
    Component: UploadImagePwa,
    Viewer: ImageViewerPwa,
    handleUpload: handleUploadPwa,
  } = useUploadImage({
    watchFn: watch,
    setValueFn: setValue,
    setErrorFn: setError,
    registerFn: register,
    field: "pwaImage",
    context: "settings",
    size: [192, 192],
    errors,
    accept: "image/png",
    suggestedDimensions: false,
  });

  const {
    Component: UploadImagePwaDelivery,
    Viewer: ImageViewerPwaDelivery,
    handleUpload: handleUploadPwaDelivery,
  } = useUploadImage({
    watchFn: watch,
    setValueFn: setValue,
    setErrorFn: setError,
    registerFn: register,
    field: "pwaImageDelivery",
    context: "settings",
    size: [192, 192],
    errors,
    accept: "image/png",
    suggestedDimensions: false,
  });

  const sections = [
    {
      label: "general",
      title: "Informações gerais",
      Component: GeneralSection,
      upload: {
        logo: {
          Component: UploadImage,
          Viewer: ImageViewer,
        },
      },
    },
    {
      label: "pwaSite",
      title: "App",
      Component: PWASiteSection,
      upload: {
        pwa: {
          Component: UploadImagePwa,
          Viewer: ImageViewerPwa,
        },
      },
    },
    {
      label: "seo",
      title: "SEO / Google Analytics",
      Component: SEOSection,
      upload: {
        favicon: {
          Component: UploadImageFavicon,
          Viewer: ImageViewerFavicon,
        },
      },
    },
    {
      label: "discount",
      title: "Formulário de descontos",
      Component: DiscountSection,
    },
    {
      label: "courier",
      title: "Entregador",
      Component: CourierSection,
      upload: {
        pwa: {
          Component: UploadImagePwaDelivery,
          Viewer: ImageViewerPwaDelivery,
        },
      },
    },
    {
      label: "order",
      title: "Pedidos",
      Component: OrderSection,
    },
    {
      label: "ticket",
      title: "Comanda",
      Component: TicketSection,
    },
    {
      label: "messages",
      title: "Mensagens",
      Component: MessagesSection,
    },
    {
      label: "social",
      title: "Redes Socias (Se não preencher, não aparecerá no site)",
      Component: SocialSection,
    },
    {
      label: "style",
      title: "Cores do site",
      Component: StyleSection,
    },
    {
      label: "analytics",
      title: "Analytics",
      Component: AnalyticsSection,
    },
    {
      label: "telegram",
      title: "Telegram",
      Component: TelegramSection,
    }
  ];

  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = React.useState<Record<string, boolean>>({});

  const handleClick = (property: string) => {
    setOpen({ ...open, [property]: !open[property] });
  };

  return (
    <Edit
      wrapperProps={{
        sx: {
          background: "transparent",
          boxShadow: "none",
        },
      }}
      headerButtons={<></>}
      isLoading={formLoading}
      saveButtonProps={{
        disabled: saveButtonProps.disabled || isLoading,
        onClick: (e: any) => {
          if (!isValid) {
            saveButtonProps.onClick(e);
            return;
          }

          setIsLoading(true);
          handleUpload(getValues("name")).then(() => {
            handleUploadFavicon(getValues("name")).then(() => {
              handleUploadPwa(getValues("name")).then(() => {
                handleUploadPwaDelivery(getValues("name")).then(() => {
                  saveButtonProps.onClick(e);
                  setIsLoading(false);
                });
              });
            });
          });
        },
      }}
      title={
        <Typography variant="h5" color="inherit" component="div">
          Configurações
        </Typography>
      }
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
        //@ts-ignore
        onSubmit={handleSubmit(saveButtonProps.onClick)}
      >
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {sections.map(({ label, title, Component, upload }) => (
            <Fragment key={label}>
              <ListItemButton onClick={() => handleClick(label)}>
                <ListItemText primary={title} />
                {open[label] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={open[label]}>
                <Box
                  sx={{
                    padding: 2,
                    mt: 2,
                  }}
                >
                  <Component
                    errors={errors}
                    register={register}
                    control={control}
                    getValues={getValues}
                    upload={upload}
                  />
                </Box>
              </Collapse>
            </Fragment>
          ))}
        </List>
      </Box>
    </Edit>
  );
};
